export const faSqlIcon = {
	prefix: "fab",
	iconName: "sqlicon",
	icon: [
		16,
		16,
		[],
		"",
		"M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM0 14.841a1.129 1.129 0 0 0 .401.823c.13.108.288.192.478.252s.411.091.665.091c.338 0 .624-.053.858-.158c.237-.106.416-.252.54-.44a1.17 1.17 0 0 0 .187-.656c0-.224-.045-.41-.135-.56a1 1 0 0 0-.375-.357a2.027 2.027 0 0 0-.565-.21l-.621-.144a.97.97 0 0 1-.405-.176a.369.369 0 0 1-.143-.299c0-.156.061-.284.184-.384c.125-.101.296-.152.513-.152c.143 0 .266.022.37.068a.624.624 0 0 1 .245.181a.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.199-.566a1.21 1.21 0 0 0-.5-.41a1.813 1.813 0 0 0-.78-.152c-.293 0-.552.05-.776.15c-.225.099-.4.24-.528.421c-.127.182-.19.395-.19.639c0 .201.04.376.123.524c.082.149.199.27.351.367c.153.095.332.167.54.213l.618.144c.207.049.36.113.462.193a.387.387 0 0 1 .153.325c0 .11-.029.207-.085.29A.558.558 0 0 1 2 15.31c-.111.047-.249.07-.413.07c-.117 0-.224-.013-.32-.04a.835.835 0 0 1-.248-.115a.579.579 0 0 1-.255-.384H0Zm6.878 1.489l-.507-.739c.176-.162.31-.362.401-.6c.092-.239.138-.507.138-.806v-.501c0-.371-.07-.693-.208-.967a1.495 1.495 0 0 0-.589-.636c-.256-.15-.561-.225-.917-.225c-.351 0-.656.075-.914.225c-.256.149-.453.36-.592.636a2.138 2.138 0 0 0-.205.967v.5c0 .37.069.691.205.965c.139.273.336.485.592.636a1.8 1.8 0 0 0 .914.222a1.8 1.8 0 0 0 .6-.1l.294.422h.788ZM4.262 14.2v-.522c0-.246.038-.456.114-.63a.91.91 0 0 1 .325-.398a.885.885 0 0 1 .495-.138c.192 0 .357.046.495.138a.88.88 0 0 1 .325.398c.077.174.115.384.115.63v.522c0 .164-.018.312-.053.445c-.035.13-.087.244-.155.34l-.106-.14l-.105-.147h-.733l.451.65a.638.638 0 0 1-.251.047a.872.872 0 0 1-.487-.147a.916.916 0 0 1-.32-.404a1.67 1.67 0 0 1-.11-.644Zm3.986 1.057h1.696v.674H7.457v-3.999h.79v3.325Z"
	]
};
